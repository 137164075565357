import "antd/dist/antd.css";
import "core-js";
import { enableES5 } from "immer";
import "src/iconFont";
import * as serviceWorker from "src/serviceWorker";

enableES5();

if (window.location.pathname?.endsWith("silent_renew")) {
  import("src/containers/silent_renew/Silent_renew");
} else if (window.location.pathname?.endsWith("callback")) {
  import("src/containers/callback/Callback");
} else {
  import("src/containers/Main");

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
